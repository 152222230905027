import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import moment from 'moment';
import jwt from 'jwt-decode';
import { isMobile } from 'react-device-detect';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import helperFunctions from '../../global/HelperService';

import {
  createResource,
  uploadFile,
  updateResource,
  getResource,
  getResourceType,
  getResourceTopic,
  submitResourceType,
  saveResourceTopic,
  resourceSelector,
  resourceTypesSelector,
  resourceTopicsSelector,
  resourceListSelector,
  getResourceList,
  removeResourceFile,
} from '../../features/Resource/ResourceSlice';
import { getServiceTrusts } from '../../features/Trust/TrustSlice';

import {
  getUserList,
  userListSelector,
  getUserListRes,
  getApprovers,
} from '../../features/User/UserSlice';

import { FormSelect } from '../../components/FormSelect';
import { FormInput } from '../../components/FormInput';
import { FormCheckBox } from '../../components/FormCheckBox';
import CustomModal from '../../components/CustomModal';
import Tags from '../../components/Tags';
import Toast from '../../components/Toast';
import { Constants } from '../../global/Constants';
import ArrowLeft from '../../assets/images/arrow_left.svg';

import './Resource.css';
import calendarIcon from '../../assets/images/calendar-icon.svg';
import clockIcon from '../../assets/images/clock.svg';
import PDFViewer from '../../components/PDFViewer';
import back from '../../assets/images/back.svg';
import { useSelector } from 'react-redux';
import { TagSearch } from '../../components/TagSearch';
import Pagination from '../../components/Pagination';
import {
  addTag,
  deleteTag,
  getTagList,
  tagListSelector,
} from '../../features/TagList/TagListSlice';
import Loading from '../../components/loading/Loading';
import { ErrorMessage } from '@hookform/error-message';
import { FormErrorMessage } from '../../components/FormErrorMessage';
import { decodeEntities } from '../../utils/decodeEntities';
import Button from '../../components/Button/Button';
import { ReactComponent as BackArrow } from '../../assets/images/back.svg';
import { Back } from '../../components/back/Back';
import { scopeProperty } from '../../global/scopePayload';

type OptionsProps = {
  label: string;
  value: number;
};

const ResourceForm = () => {
  const tabIndex = 0;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const { isSuccess, isError, resource } = useAppSelector(resourceSelector);
  const resourceTypes: any = useAppSelector(resourceTypesSelector);
  const resourceTopics: any = useAppSelector(resourceTopicsSelector);
  const resourceList: any = useAppSelector(userListSelector);
  const tagListArray: any = useAppSelector(tagListSelector);

  const jwtDecode: any = jwt(localStorage.getItem('token') || '');
  console.log("🚀 ~ ResourceForm ~ jwtDecode:", jwtDecode)
  const accessToken: any = jwt(jwtDecode.accessToken || "");
  console.log("🚀 ~ ResourceForm ~ accessToken:", accessToken)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: 'onChange' });
  const {
    register: register2,
    reset: reset2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm({ mode: 'all' });
  const {
    register: register3,
    reset: reset3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = useForm({ mode: 'all' });

  const [tags, setTag] = useState<string[]>([]);
  const [publishFroValue, setPublishFor] = useState('allUsers');
  const [contentType, setContentType] = useState('content');
  const [videoType, setVideoContentType] = useState('');
  const [selectedResource, setResource] = useState('');
  const [uploadedFileData, setUploadedFileData] = useState<any>({});
  const [videoUploadPercentage, setVideoUpload] = useState(0);
  const [isBackAlertModalOpen, setBackAlertModal] = useState(false);
  const [isRequestSubmittedModalOpen, setRequestSubmittedModal] =
    useState(false);
  const [isAddNewTopic, setAddNewTopic] = useState(false);
  const [isContentTypeModalOpen, setContentTypeModalState] = useState(false);
  const [selectedFile, setFileData] = useState({} as any);
  const [selectedFilePreview, setFilePreview] = useState('' as any);
  const [selectedResType, setResourceType] = useState<string>('');
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const [createdContentTypeName, setCreatedContentTypeName] = useState('');
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [sort, setSort] = useState<{ sortField: string; sortOrder: number }>({
    sortField: 'title',
    sortOrder: 1,
  });
  const [searchText, setSearchText] = useState<string>('');
  const [searchTextTags, setSearchTextTags] = useState<string>('');
  const timerId: React.MutableRefObject<number | undefined> = useRef<number>();
  const [filters, setFilters] = useState({});
  const [pageNo, setPageNo] = React.useState(1);
  // const [approverUser, setApproverUser] = useState<any>({});

  const [isCurrentTagModalOpen, setCurrentTagModalState] = useState(false);
  const [pageNoTags, setPageNoTags] = React.useState(1);
  const [tagList, setTagList] = useState<any>([]);
  const [serviceTrusts, setServiceTrusts] = useState<any>([]);
  const [selectedServiceTrust, setSelectedServiceTrust] = useState<any>([]);
  const [errorDate, setErrorDate] = useState('');
  const [errorFileSize, setErrorFileSize] = useState('');
  const [minimumDate, setMinimumDate] = useState<any>('');
  const [loader, setloader] = useState(false);
  const [isDisabledEndDate, setIsDisabledEndDate] = useState(true);
  const [isDisabledEndon, setIsDisabledEndsOn] = useState(true);
  // const [isCurrentTagModalOpen, setCurrentTagModalState] = useState(false);

  // const currentTags = ["Hypotension", "Stroke", "Dizziness", "Fainting", "Heart attack", "Arteries", "Name of a tag"]
  const currentTags = [
    { label: 'Hypotension', value: 1 },
    { label: 'Stroke', value: 2 },
    { label: 'Dizziness', value: 3 },
    { label: 'Fainting', value: 4 },
    { label: 'Heart attack', value: 5 },
    { label: 'Arteries', value: 6 },
    { label: 'Name of a tag', value: 7 },
  ];

  const [selectedTags, setSelectedTags] = useState<any>([]);

  const shortenRole: Array<string> = jwtDecode.scopePermissions || [];

  // console.log("selectedTags", selectedTags)
  // const [selectedTags, setSelectedTags] = useState<OptionsProps[]>(currentTags);
  // const [resourceDropdown, setResourceDropdown] = useState([])
  // const [sort, setSort] = useState<{ sortField: string; sortOrder: number }>({
  //   sortField: "title",
  //   sortOrder: 1,
  // });
  // const [searchText, setSearchText] = useState<string>("");
  // const [filters, setFilters] = useState({});
  // const [pageNo, setPageNo] = React.useState(1);
  // const [approverUser, setApproverUser] = useState<any>({})

  const ReviewDateInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <span
      tabIndex={tabIndex}
      className={
        'review-date-input onkeyup' +
        (isMobile ? '-mobile ' : ' ') +
        'cursor-pointer'
      }
      onClick={onClick}
      ref={ref}
    >
      <img src={calendarIcon} alt='tag-close' />
      <span className='ms-2'>{value}</span>
    </span>
  ));

  const CustomDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
    <div
      tabIndex={tabIndex}
      className='custom-date-picker onkeyup cursor-pointer flex justify-between items-center w-full px-4'
      onClick={onClick}
      ref={ref}
    >
      <span className='ms-2'>{value}</span>
      <img src={calendarIcon} alt='calendar' />
    </div>
  ));

  const CustomTimePicker = forwardRef(({ value, onClick }: any, ref: any) => (
    <div
      tabIndex={tabIndex}
      className='custom-date-picker onkeyup cursor-pointer flex justify-between items-center w-full px-4'
      onClick={onClick}
      ref={ref}
    >
      <span className='ms-2'>{value}</span>
      <img src={clockIcon} alt='clock' />
    </div>
  ));

  const ages = [
    { label: 'Adult', value: 'Adult' },
    { label: 'CYP', value: 'CYP' },
    { label: 'Older Adults', value: 'Older Adults' },
    { label: 'Paediatrics', value: 'Paediatrics' },
    { label: 'Child (0-18)', value: 'Child (0-18)' },
    { label: 'Over 18', value: 'Over 18' },
    { label: 'Adult (19 – 64)', value: 'Adult (19 – 64)' },
    { label: 'Elderly (65+)', value: 'Elderly (65+)' },
  ];

  const services = [
    { label: 'NHS', value: 'NHS' },
    { label: 'C & M', value: 'C & M' },
  ];

  useEffect(() => {
    fetchApprovers();
  }, []);

  const fetchApprovers = (requestQuery?: any) => {
    setloader(true);
    dispatch(getApprovers({}))
      .unwrap()
      .then((data: any) => {
        console.log(
          '🚀 ~ file: ResourceForm.tsx:211 ~ .then ~ data:',
          data.data
        );
        setloader(false);
        setResourceDropdown(data?.data);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  useEffect(() => {
    if (publishFroValue !== 'specificService') {
      setSelectedServiceTrust([]);
    }
  }, [publishFroValue]);
  //console.log("contentType here", contentType)

  useEffect(() => {
    if (id) {
      console.log('log refresh resource :>>', resource);
      setloader(true);
      dispatch(getResource(id))
        .unwrap()
        .then((data: any) => {
          reset(resource);
          const tags = [...resource.tags];
          setTag(tags);
          setFileData({ name: resource.file });
          setResource(resource.type);
          setValue('title', decodeEntities(resource?.title));
          setValue('selectedApprover', resource.selectedApprover.email);
          //let selectObjFocus = {email:resource.selectedApprover.email, id:resource.selectedApprover.id, name : resource.selectedApprover.name}
          //setApproverUser(resource.selectedApprover.email);
          setSelectedTags(resource.tags);
          //console.log("🚀 ~ file: ResourceForm.tsx:268 ~ .then ~ resource.selectedApprover.email:", resource.selectedApprover)
          if (resource?.publishForInSpecific.length) {
            setPublishFor('specificService');
          }
          setValue('resourceBody', decodeEntities(resource?.resourceBody));
          if (resource.file && resource.resourceBody !== '<div></div>') {
            setValue('contentType', 'doc-content');
            setContentType('doc-content');
            if (resource.filePath !== '') {
              setFilePreview(resource.filePath);
            }
          } else if (resource.file && resource.resourceBody == '<div></div>') {
            setValue('contentType', 'upload');
            setContentType('upload');
            if (resource.filePath !== '') {
              setFilePreview(resource.filePath);
            }
          } else if (
            !resource.file &&
            resource.resourceBody !== '<div></div>'
          ) {
            // setValue("contentType", "content");
            setContentType('content');
          }
          if (resource.type === 'Videos' && resource.file) {
            setValue('videoType', 'upload');
            setVideoContentType('upload');
          } else {
            setValue('videoType', 'url');
            setVideoContentType('url');
          }
          setloader(false);
        })
        .catch((e) => {
          setloader(false);
          console.log('error---', e);
        });
    }
    setloader(true);
    dispatch(getResourceType('1'))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
    dispatch(getResourceTopic('1'))
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
    fetchResources();
    fetchTagLists();
    fetchServiceTrusts();
  }, [id]);

  const fetchServiceTrusts = () => {
    let data = {
      level1UserId: jwtDecode.level1User,
    };
    setloader(true);
    dispatch(getServiceTrusts(data))
      .unwrap()
      .then((data: any) => {
        setServiceTrusts(data?.data?.trusts);
        setloader(false);
      })
      .catch((e) => {
        console.log('error', e);
        setloader(false);
      });
  };
  const fetchTagLists = (requestQuery?: any) => {
    setloader(true);
    dispatch(
      getTagList({
        searchString: searchTextTags,
        page: pageNoTags,
        perPage: 7,
        ...requestQuery,
      })
    )
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };
  const fetchResources = (requestQuery?: any) => {
    setloader(true);
    dispatch(
      getUserListRes({
        sortOrder: sort.sortOrder,
        sortBy: sort.sortField,
        searchString: searchText,
        filter: filters,
        page: pageNo,
        perPage: Constants.ROWS_PER_PAGE,
        //fromReviewDate: requestQuery.fromReviewDate,
        //toReviewDate: requestQuery.toReviewDate,//
        ...requestQuery,
      })
    )
      .unwrap()
      .then((data: any) => {
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
  };

  // useEffect(() => {
  //   if (resourceList?.users?.length) {
  //     console.log("🚀 ~ file: ResourceForm.tsx:385 ~ useEffect ~ resourceList:", resourceList);
  //     setResourceDropdown(() => {
  //       return resourceList?.users?.filter((item: any) => {
  //         // if (item?.roles?.roles?.includes("Content Approver")) {
  //         //   return item;
  //         // }
  //       });
  //     });
  //   }
  // }, [resourceList]);

  useEffect(() => {
    if (tagListArray?.tagList?.tags) {
      setTagList(tagListArray?.tagList?.tags);
    }
  }, [tagListArray]);

  // useEffect(() => {
  //   if (id && resource && resource?.selectedApprover) {
  //     setApproverUser(resource.selectedApprover);
  //   }
  // }, [id]);

  const onSubmit = (resource: any) => {
    console.log('log resource-draft :>>', resource);
    // resource.approverSelect = approverUser;
    resource.publishForInSpecific =
      publishFroValue == 'specificService' ? selectedServiceTrust : '';
    resource.content = resource.resourceBody || '<div></div>';
    resource.trustId = jwtDecode.trustId;
    resource.level1User = jwtDecode.level1User;
    resource.tags = selectedTags;
    resource.publishFor = publishFroValue;
    resource.regionalId = accessToken.regionalId;
    const submitAction = resource._id ? updateResource : createResource;
    let formData: any = new FormData();
    if (selectedFile && selectedFile.size) {
      formData.append('file', selectedFile);
    } else {
      formData = null;
    }
    if (uploadedFileData.file) {
      resource = { ...resource, ...uploadedFileData, uploaded: true };
    }
    if (!resource.reviewDate) resource.reviewDate = nextWeek();
    console.log('resource 406 :>>', resource);
    setloader(true);
    dispatch(submitAction({ resource, formData }))
      .unwrap()
      .then((data: any) => {
        helperFunctions.showToast(
          `${
            resource.submitForReview
              ? Constants.RESOURCE_CONTENT_UPDATED_AND_SFA
              : id
              ? Constants.RESOURCE_CONTENT_UPDATED
              : Constants.RESOURCE_CONTENT_SAVED
          }`,
          'success'
        );

        goToTableView();
        setloader(false);
      })
      .catch((e) => {
        setloader(false);
        console.log('error---', e);
      });
  };

  const publishResource = (resource: any) => {
    console.log('log resource-draft :>>', resource);
    // resource.approverSelect = approverUser;
    resource.publishForInSpecific =
      publishFroValue == 'specificService' ? selectedServiceTrust : '';
    resource.content = resource.resourceBody || '<div></div>';
    resource.trustId = jwtDecode.trustId;
    resource.level1User = jwtDecode.level1User;
    resource.tags = selectedTags;
    resource.publishFor = publishFroValue;
    resource.regionalId = accessToken.regionalId;
    const submitAction = resource._id ? updateResource : createResource;
    let formData: any = new FormData();
    if (selectedFile && selectedFile.size) {
      formData.append('file', selectedFile);
    } else {
      formData = null;
    }
    if (uploadedFileData.file) {
      resource = { ...resource, ...uploadedFileData, uploaded: true };
    }
    if (!resource.reviewDate) resource.reviewDate = nextWeek();
    console.log('resource 406 :>>', resource);
    setloader(true);
    dispatch(submitAction({ resource, formData }))
      .unwrap()
      .then((data: any) => {
        helperFunctions.showToast(
          `${
            resource.submitForReview
              ? Constants.RESOURCE_CONTENT_UPDATED_AND_SFA
              : id
              ? Constants.RESOURCE_CONTENT_UPDATED
              : Constants.RESOURCE_CONTENT_SAVED
          }`,
          'success'
        );

        goToTableView();
        setloader(false);
      })
      .catch((e) => {
        setloader(false);
        console.log('error---', e);
      });
  };

  // const handlePublishForValue = (value: string) => {
  //   if(value === 'specificService')
  //     resource.publishForInSpecific = ''

  //   setPublishFor(value)
  // }

  const handleApprovalSubmit = (resource: any) => {
    console.log(resource);
    resource.submitForReview = true;

    if (!resource.reviewDate) resource.reviewDate = nextWeek();

    if (
      resource &&
      resource.publishFor &&
      resource.publishFor === 'specificService' &&
      selectedServiceTrust &&
      selectedServiceTrust.length === 0
    ) {
      helperFunctions.showToast(
        'Please select atleast any one service trust',
        'error'
      );
      return;
    }
    onSubmit(resource);
  };

  const onEnter = (e: KeyboardEvent) => {
    const tagValue = getValues('tagValue');
    if (e.key === 'Enter' && tagValue) {
      let payload = {
        name: tagValue,
      };
      setloader(true);
      dispatch(addTag(payload))
        .unwrap()
        .then((res: any) => {
          console.log('data form', res);
          setSelectedTags([
            ...selectedTags,
            { name: res?.data?.name, id: res?.data?._id, type: 0 },
          ]);
          setValue('tagValue', '');
          fetchTagLists();
          setloader(false);
        })
        .catch((e) => {
          if (e.status === 409) {
            setSelectedTags([
              ...selectedTags,
              {
                name: e?.data?.data[0]?.name,
                id: e?.data?.data[0]?._id,
                type: 0,
              },
            ]);
          }
          setloader(false);
          console.log('error---', e);
        });
    }
  };

  const goToTableView = () => {
    navigate(`/resource`);
  };

  const removeTags = (key: number) => {
    let isUserInputTag = selectedTags.filter((item: any) => {
      return item.id === key && item.type === 0;
    });
    if (isUserInputTag.length) {
      setloader(true);
      dispatch(deleteTag({ id: key }))
        .unwrap()
        .then((res: any) => {
          setSelectedTags(selectedTags.filter((item: any) => item.id !== key));
          setloader(false);
        });
    } else {
      let newTags = selectedTags.filter((item: any) => item.id !== key);
      setSelectedTags(newTags);
    }
  };

  const onContentTypeModalClose = () => {
    setContentTypeModalState(false);
    reset2();
  };

  const onContentTypeSubmit = (contentType: any) => {
    setloader(true);
    dispatch(submitResourceType(contentType))
      .unwrap()
      .then((data: any) => {
        onContentTypeModalClose();
        setRequestSubmittedModal(true);
        setCreatedContentTypeName(contentType.name);
        dispatch(getResourceType('1'));
        setloader(false);
      })
      .catch((e) => {
        if (e.status === 409) {
          helperFunctions.showToast(
            `${Constants.RESOURCE_TYPE_DUPLICATE}`,
            'error'
          );
        }
        setloader(false);
        console.log('error---', e);
      });
  };

  const onTopicSubmit = (topic: any) => {
    setloader(true);
    dispatch(saveResourceTopic(topic))
      .unwrap()
      .then((data: any) => {
        setAddNewTopic(false);
        reset3();
        // toast.custom((t) => <Toast id={t.id} title="SUCCESS test" content={Constants.CONTENT_TOPIC_SUCCESS} />);
        helperFunctions.showToast(
          `${Constants.CONTENT_TOPIC_SUCCESS}`,
          'success'
        );

        dispatch(getResourceTopic('1'));
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        if (e.status === 409) {
          // toast.custom((t) => <Toast id={t.id} title="ERROR" content={Constants.CONTENT_TOPIC_DUPLICATE} />);
          helperFunctions.showToast(
            `${Constants.CONTENT_TOPIC_DUPLICATE}`,
            'error'
          );
        }
        setloader(false);
      });
  };

  const onResourceTypeChange = (value: any, selectedObj: any) => {
    // console.log("🚀 ~ file: ResourceForm.tsx ~ line 290 ~ onResourceTypeChange ~ selectedObj", selectedObj);
    let contentType = 'content';
    setResource(value);
    setResourceType(selectedObj.type);
    if (Constants.CONTENT_TYPE_VIEW_LIST.includes(value)) {
      contentType = '';
    }
    // Clear file and file path while changing the resource type
    // if (resource?.file && resource?.filePath) {
    //   resource.file = "";
    //   resource.filePath = "";
    // }
    setContentType(contentType);
    setValue('contentType', '');
    setValue('file', '');
    setValue('filePath', '');
    setFileData('');
    setFilePreview('');
  };

  const onServiceTrustsChange = (value: any, selectedObj: any) => {
    // setServiceTrusts(value);
    console.log('selectedObj', selectedObj);
    setSelectedServiceTrust({
      id: selectedObj.id,
      email: selectedObj.contact.email,
    });
  };

  // const onApproverUserChange = (value: any, selectedObj: any) => {
  //   // console.log("Selected OBJ", selectedObj);
  //   // console.log("Selected OBJ", `${selectedObj.name}  ${selectedObj.lastName}`);
  //   setApproverUser({
  //     email: selectedObj.email,
  //     id: selectedObj.id,
  //     name: `${selectedObj.firstName}  ${selectedObj.lastName}`,
  //   });
  // };

  const getContentTypeView = () =>
    Constants.CONTENT_TYPE_VIEW_LIST.includes(selectedResource);

  const onFileUpload = (fileData: any) => {
    console.log('onFileUpload---', fileData);
    const file: any = fileData[0];
    if (validateSize(file)) {
      console.log('success');
      const objectUrl = URL.createObjectURL(file);
      setFilePreview(objectUrl);
      setFileData(file);
      setTimeout(() => {
        const iframe = document.getElementById('pdfviewer') as any;
        const innerDoc =
          iframe.contentDocument ||
          (iframe.contentWindow && iframe.contentWindow.document);
        if (innerDoc) {
          const imgTags = innerDoc.getElementsByTagName('img');
          if (imgTags[0]) {
            imgTags[0].style.width = '100%';
          }
        }
      }, 500);
      setErrorFileSize('');
    } else {
      console.log('not success');
      setErrorFileSize('File size should be within 5MB.');
    }
  };

  const onVideUpload = () => {
    let formData: any = new FormData();
    if (selectedFile && selectedFile.size) {
      formData.append('file', selectedFile);
    } else {
      formData = null;
    }
    let percentage = 10;
    const percentageInterval = setInterval(() => {
      setVideoUpload((percentage += 10));
      if (percentage > 80) {
        clearInterval(percentageInterval);
      }
    }, 500);
    setloader(true);
    dispatch(uploadFile({ formData }))
      .unwrap()
      .then((data: any) => {
        clearInterval(percentageInterval);
        setVideoUpload(100);
        setUploadedFileData(data.data);
        setFileData({ name: selectedFile.name });
        setloader(false);
      })
      .catch((e) => {
        clearInterval(percentageInterval);
        setVideoUpload(0);
        setFileData({ name: selectedFile.name });
        console.log('error---', e);
        setloader(false);
      });
  };

  const showContentEditor = () => {
    let flag = false;
    if (contentType === 'content' || contentType === 'doc-content') {
      flag = true;
    }
    if (selectedResource === 'Events & Groups') {
      flag = false;
    }
    if (selectedResource === 'Videos') {
      flag = false;
    }
    if (selectedResource === 'Videos' && videoType === 'upload') {
      flag = false;
    }
    return flag;
  };

  const onClearFile = (fileName: String) => {
    let data = {
      fileName: fileName,
      resource: resource,
    };
    setloader(true);
    dispatch(removeResourceFile(data))
      .unwrap()
      .then((data: any) => {
        console.log('dataa', data);
        setloader(false);
      })
      .catch((e) => {
        console.log('error---', e);
        setloader(false);
      });
    setFileData('');
    setFilePreview('');
  };

  const modalData = {
    isOpen: isBackAlertModalOpen,
    title: 'Your edits haven’t been saved',
    content: 'Do you want to leave this page without saving your changes?',
    primaryButton: (
      <span
        tabIndex={tabIndex}
        className='acces-btn secondary-btn onkeyup'
        onClick={() => goToTableView()}
      >
        LEAVE WITHOUT SAVING
      </span>
    ),
    secondaryButton: (
      <span
        className='acces-btn onkeyup !btn-violet ms-4'
        onClick={() => setBackAlertModal(false)}
      >
        CANCEL
      </span>
    ),
    closeAction: setBackAlertModal,
  };

  const resourceTypeSubmittedModal = {
    isOpen: isRequestSubmittedModalOpen,
    title: 'Submitted successfully',
    content: `Your new content type ${createdContentTypeName}, has been submitted for review. You will be notified once it's reviewed.`,
    closeAction: setRequestSubmittedModal,
  };

  const contentTypeModal = {
    isOpen: isContentTypeModalOpen,
    title: 'Request to add new content',
    content: (
      <form
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        className='tex-align-l'
      >
        <FormInput
          id='name'
          type='text'
          name='name'
          label='Content type name'
          placeholder='Add a name for your content type'
          register={register2}
          rules={{
            required: 'This field is required',
          }}
          errors={errors2}
        />
        <FormInput
          id='description'
          type='textarea'
          name='description'
          label='Description'
          placeholder='Add a description for your content type'
          register={register2}
          rules={{
            required: 'This field is required',
          }}
          errors={errors2}
        />
      </form>
    ),
    primaryButton: (
      <span
        tabIndex={tabIndex}
        className='acces-btn onkeyup btn-violet'
        onClick={handleSubmit2(onContentTypeSubmit)}
      >
        SUBMIT
      </span>
    ),
    closeAction: onContentTypeModalClose,
  };

  const nextWeek = () => {
    var today = new Date();
    var nextWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    return nextWeek;
  };

  const compareDatesWithMoment = (date1: any, date2: any) => {
    console.log('toDate', date1);
    console.log('fromDate', date2);
    return moment(date1).diff(date2, 'day');
  };
  function validateSize(file: any) {
    const fileSize = file.size / 1024 / 1024; // in MiB

    if (fileSize > 5) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setSelectedServiceTrust([]);
  }, [publishFroValue]);

  function imageUploadHandler(blobInfo, success, failure, progress) {
    let image_size = blobInfo.blob().size / 1000;
    var max_size = 5000;
    if (image_size > max_size) {
      failure(
        'Image is too large( ' +
          image_size +
          ') ,Maximum image size is:' +
          max_size +
          ' kB'
      );
      return;
    }
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open(
      'POST',
      `${process.env.REACT_APP_SERVICE_PHR}/api/content/file-upload`
    );
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    xhr.upload.onprogress = function (e) {
      progress((e.loaded / e.total) * 100);
    };

    xhr.onload = function () {
      var json;

      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      success(json.data.filePath);
    };

    xhr.onerror = function () {
      failure(
        'Image upload failed due to a XHR Transport error. Code: ' + xhr.status
      );
    };

    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  }

  const renderSubHeader = () => {
    if (id) {
      return (
        <div className='app-sub-header row g-0 d-mflex items-center'>
          <div
            tabIndex={tabIndex}
            className='flex secondary-text cursor-pointer fw-bold mb-2 mb-sm-0 onkeyup'
            onClick={() => setBackAlertModal(true)}
          >
            <img src={ArrowLeft} alt='arrow-left' className='me-1' />
            Back to the contents table
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={
            'app-sub-header' +
            (isMobile ? ' resource-content-mobile ' : ' resource-content ') +
            'row g-0 flex items-center'
          }
        >
          <div className='col-6 cursor-pointer fw-bold' tabIndex={tabIndex}>
            Today’s date: {moment(new Date()).format('DD/MM/YYYY')}
          </div>
          {process.env.REACT_APP_REVIEW_DATE != 'false' ? (
            <div
              className={
                'col-6' +
                (isMobile
                  ? ' text-end'
                  : ' flex align-items-end justify-content-end')
              }
            >
              <span
                tabIndex={tabIndex}
                className={'fw-bold' + (!isMobile ? ' me-2' : '')}
              >
                Set approval date1:
              </span>
              <div>
                <Controller
                  name='reviewDate'
                  render={({ field: { ref, value, onChange } }) => {
                    return (
                      <DatePicker
                        {...register('reviewDate')}
                        onChange={onChange}
                        selected={value ? moment(value).toDate() : nextWeek()}
                        dateFormat='dd/MM/yyyy'
                        ref={ref}
                        customInput={<ReviewDateInput />}
                        minDate={moment().toDate()}
                      />
                    );
                  }}
                  control={control}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  const addNewTopic = (
    <div className='add-topic' id='new_topic'>
      <h3 className='topic-header text-baseColor fw-bold'>Add new topic</h3>
      <div className='container'>
        <FormInput
          id='name'
          type='text'
          name='name'
          placeholder='New topic name'
          register={register3}
          rules={{
            required: 'Please enter a topic name',
          }}
          errors={errors3}
        />
        <div className='flex justify-end gap-4'>
          <Button
            btntype={'button'}
            onClick={() => {
              setAddNewTopic(false);
              reset3();
            }}
            text='Cancel'
            addClass='btn halo'
          />
          <Button
            btntype={'button'}
            text='Add topic'
            addClass='btn primary-btn'
            onClick={handleSubmit3(onTopicSubmit)}
          />
        </div>
      </div>
    </div>
  );

  const renderVideoSection = () => {
    return (
      <>
        {/* <FormInput id="" type="textarea" name="resourceBody" register={register} label="Video caption" placeholder="Add a video caption" errors={errors} /> */}
        <FormInput
          id=''
          type='textarea'
          name='resourceBody'
          register={register}
          label='Video caption'
          placeholder='Add a video caption'
          errors={errors}
        />
        <div className='header-label' tabIndex={tabIndex}>
          Video source
        </div>
        <div className='row g-0 mb-0 mb-md-4'>
          <div className='col-12 col-md-4'>
            <FormCheckBox
              type='radio'
              id='uploadVideoCheckbox'
              label='Direct upload'
              name='videoType'
              value='upload'
              register={register}
              rules={
                {
                  // required: "This field is required",
                }
              }
              errors={errors}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVideoContentType(e.target.value);
                setValue('videoUrl', '');
                // setValue('file', '');
                // setValue('filePath', '');
              }}
            />
          </div>
          <div className='col-12 col-md-4'>
            <FormCheckBox
              type='radio'
              id='videoUrlCheck'
              label='Add video URL'
              name='videoType'
              value='url'
              register={register}
              rules={
                {
                  // required: "This field is required",
                }
              }
              errors={errors}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setVideoContentType(e.target.value);
                // setValue('videoUrl', '');
                setValue('file', '');
                setValue('filePath', '');
              }}
            />
          </div>
        </div>
        {videoType && videoType === 'upload' && (
          <>
            <div className='header-label' tabIndex={tabIndex}>
              Direct upload
            </div>
            {!selectedFile ? (
              <>
                <div className='select-doc form-width mb-50 flex items-center justify-content-center'>
                  <button className='btn btn-violet' type='button'>
                    <input
                      type='file'
                      name='file'
                      accept='video/mp4,video/x-m4v,video/*'
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        onFileUpload(event.target.files);
                      }}
                    />
                    SELECT VIDEO
                  </button>
                </div>

                {errorFileSize !== '' && (
                  <span style={{ color: 'rgb(195, 31, 31)', fontSize: '16px' }}>
                    {errorFileSize}
                  </span>
                )}
              </>
            ) : (
              <>
                {!videoUploadPercentage ? (
                  <div className='file-preview form-width mb-50'>
                    <div className='title mb-3' tabIndex={tabIndex}>
                      {selectedFile.name}
                    </div>
                    <div className='flex justify-content-center mb-4'>
                      <video controls>
                        <source src={selectedFilePreview} type='video/mp4' />
                        Your browser does not support HTML video.
                      </video>
                    </div>
                    <div className='video-uploader-block'>
                      <span
                        tabIndex={tabIndex}
                        className='acces-btn secondary-btn me-3 onkeyup'
                        onClick={() => onClearFile(selectedFile.name)}
                      >
                        CLEAR
                      </span>
                      <span
                        tabIndex={tabIndex}
                        className='btn primary-btn'
                        onClick={onVideUpload}
                      >
                        UPLOAD VIDEO
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='progress-container form-width mb-50'>
                    <div className='mb-3' tabIndex={tabIndex}>
                      {selectedFile.name}
                    </div>
                    <div className='progress mb-2'>
                      <div
                        className='progress-bar'
                        role='progressbar'
                        style={{ width: `${videoUploadPercentage}%` }}
                        aria-valuenow={videoUploadPercentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {videoType && videoType === 'url' && (
          <>
            {/* <div className="header-label" tabIndex={tabIndex}>
              {`${selectedResource} Content` || "Resource body"}
            </div> */}
            <FormInput
              id='url'
              type='text'
              name='videoUrl'
              label='Video Url'
              placeholder='Add Url'
              register={register}
              rules={{
                required: 'This field is required',
              }}
              errors={errors}
            />
          </>
        )}
      </>
    );
  };

  // Close current tag model functions
  const onCurrentTagsModalClose = () => {
    setCurrentTagModalState(false);
    setSearchTextTags('');
    fetchTagLists();
  };

  // Add current tags search function
  const handleSearchChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timerId.current);
      setSearchTextTags(value);
      setPageNo(1);
      timerId.current = setTimeout(() => {
        fetchTagLists({ page: 1, searchString: value });
      }, 500) as any;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Current tag table pagination active
  const activeHandler = (clickedActive: string) => {
    setPageNoTags(parseInt(clickedActive));
    fetchTagLists({ page: clickedActive });
  };

  // Add tag model
  const currentTagModal = {
    isOpen: isCurrentTagModalOpen,
    title: 'List of current tags',
    content: (
      <div className='current-tag-area'>
        {/* Tag Search */}
        <TagSearch handleSearchChange={handleSearchChange} />

        {/* Tag Selected count */}
        <div className='tag-count'>
          <p tabIndex={tabIndex}>
            <span>{selectedTags.length} </span>
            tags have been selected
          </p>
        </div>

        {/* Selected tag list */}
        <div className='selected-tag'>
          <div className='mb-4'>
            {selectedTags &&
              selectedTags?.map((tag: any, key) => (
                <Tags
                  key={tag.id}
                  customKey={tag.id}
                  name={tag.name}
                  removeTags={removeTags}
                />
              ))}
          </div>
        </div>

        {/* Tag list table */}
        <div className='tag-list-block'>
          {tagList?.length !== 0 &&
            tagList.map((item: any, index: any) => (
              <div className='tag_list_group' key={item?._id}>
                <div className='role-permission flex'>
                  <input
                    id={`contentApprover_popup${index}`}
                    className='me-2 filter-checkbox form-check-input'
                    type='checkbox'
                    value={item?._id}
                    onChange={(e) => {
                      console.log('e.target.checked', e.target.checked);
                      console.log('e.target.checked', e.target.value);
                      if (e.target.checked) {
                        setSelectedTags([
                          ...selectedTags,
                          { name: item.name, id: item?._id, type: 1 },
                        ]);
                      } else {
                        removeTags(item?._id);
                      }
                    }}
                    checked={selectedTags
                      .map((tag: any) => tag.id)
                      .includes(item?._id)}
                  />
                  <label
                    className='form-check-label filter-label'
                    htmlFor={`contentApprover_popup${index}`}
                  >
                    {item.name}
                  </label>
                </div>
              </div>
            ))}
        </div>
      </div>
    ),
    primaryButton: (
      <span
        tabIndex={tabIndex}
        role='button'
        className='btn primary-btn'
        onClick={onCurrentTagsModalClose}
      >
        Add tags
      </span>
    ),
    closeAction: onCurrentTagsModalClose,
  };

  return (
    <div className='outer-container'>
      <div className='container-header'>
        <div className='flex gap-3 items-center'>
          {/* <Back btntype="button" onClick={() => setBackAlertModal(true)} /> */}
          <h3 className='text-bold text-baseColor'>
            {id ? <>Edit content</> : <>Create new resource</>}
          </h3>
        </div>
      </div>
      <div className='create-resource'>
        <Loading status={loader} />
        <CustomModal {...modalData} />
        <CustomModal {...contentTypeModal} />
        <CustomModal {...currentTagModal} />
        <CustomModal {...resourceTypeSubmittedModal} />

        {renderSubHeader()}
        <div className='inner-container'>
          <div className='container-xl'>
            <form onSubmit={handleSubmit(handleApprovalSubmit)}>
              <div className='form-container'>
                <FormSelect
                  id='type'
                  name='type'
                  label='Resource type'
                  placeholder='Select content type'
                  // linkText={
                  //   <span tabIndex={tabIndex} className="focusto" focus-to="focus_popup " onClick={() => setContentTypeModalState(true)}>
                  //     Request to add new content type
                  //   </span>
                  // }
                  options={resourceTypes}
                  labelKey='name'
                  valueKey='name'
                  control={control}
                  register={register}
                  onSelectChange={(value: any, selectedObj: any) => {
                    onResourceTypeChange(value, selectedObj);
                    setValue('resourceBody', '');
                  }}
                  rules={{
                    required: 'This field is required',
                  }}
                  errors={errors}
                />

                <FormInput
                  id='title'
                  type='text'
                  name='title'
                  label='Resource title'
                  placeholder='Add a title for your content'
                  register={register}
                  rules={{
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Minimum 3 characters is required',
                    },
                    pattern: {
                      value: /^(?=\s*\S).{1,100}$/g,
                      message:
                        'Resource title allowed maximum 100 characters only',
                    },
                  }}
                  errors={errors}
                />
                <FormSelect
                  id='topic'
                  name='topic'
                  label='Topic'
                  placeholder='Select topic'
                  linkText={
                    <span
                      tabIndex={tabIndex}
                      role='button'
                      className=' underline-btn primary'
                      focus-to='new_topic'
                      onClick={() => setAddNewTopic(true)}
                    >
                      Add new topic
                    </span>
                  }
                  options={resourceTopics}
                  labelKey='name'
                  valueKey='name'
                  control={control}
                  register={register}
                  rules={{
                    required: 'This field is required',
                  }}
                  errors={errors}
                />
                {isAddNewTopic && addNewTopic}

                <FormSelect
                  id='ageGroup'
                  name='ageGroup'
                  label='Age group'
                  placeholder='Please select an age group'
                  isSearchable={false}
                  options={ages}
                  labelKey='label'
                  valueKey='label'
                  control={control}
                  register={register}
                  errors={errors}
                />
                {getContentTypeView() && (
                  <>
                    <div className='header-label'>{selectedResource} type</div>
                    <div className='row g-0 mb-0 mb-md-4'>
                      <div className='col-12 col-md-4'>
                        <FormCheckBox
                          type='radio'
                          id='uploaddocCheckbox'
                          label='Upload Document'
                          name='contentType'
                          value='upload'
                          register={register}
                          rules={
                            {
                              // required: "This field is required",
                            }
                          }
                          errors={errors}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setContentType(e.target.value);
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4'>
                        <FormCheckBox
                          type='radio'
                          id='contentCheckbox'
                          label='Content'
                          name='contentType'
                          value='content'
                          register={register}
                          errors={errors}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setContentType(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='row g-0 mb-50'>
                      <div className='col-12 col-md-4'>
                        <FormCheckBox
                          type='radio'
                          id='uploadAndContentCheckbox'
                          label='Both document and content'
                          name='contentType'
                          value='doc-content'
                          register={register}
                          rules={
                            {
                              // required: "This field is required",
                            }
                          }
                          errors={errors}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setContentType(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {contentType &&
                  selectedResource !== 'Videos' &&
                  (contentType === 'upload' ||
                    contentType === 'doc-content') && (
                    <>
                      <div className='header-label' tabIndex={tabIndex}>
                        Upload document
                      </div>
                      {!selectedFile || selectedFile?.name == '' ? (
                        <>
                          <div className='select-doc form-width mb-50 flex items-center justify-content-center'>
                            <label
                              tabIndex={tabIndex}
                              htmlFor='file_upload'
                              className='btn btn-violet file_upload_btn flex justify-content-center onkeyup'
                            >
                              <input
                                accept='application/pdf'
                                id='file_upload'
                                type='file'
                                name='file'
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onFileUpload(event.target.files);
                                }}
                              />
                              UPLOAD
                            </label>
                          </div>
                          {errorFileSize !== '' && (
                            <span
                              style={{
                                color: 'rgb(195, 31, 31)',
                                fontSize: '16px',
                              }}
                            >
                              {errorFileSize}
                            </span>
                          )}
                        </>
                      ) : (
                        <div className='file-preview form-width mb-50'>
                          <div className='title mb-3'>{selectedFile.name}</div>
                          <div className='pdf-container mx-auto mb-4'>
                            <PDFViewer renderData={selectedFilePreview} />
                          </div>
                          {/* {!id && ( */}
                          <div className='flex justify-content-center'>
                            <span
                              className='acces-btn btn-violet'
                              onClick={() => onClearFile(selectedFile.name)}
                            >
                              CLEAR
                            </span>
                          </div>
                          {/* )} */}
                        </div>
                      )}
                    </>
                  )}
                {selectedResource === 'Videos' && renderVideoSection()}
                {showContentEditor() && (
                  <div className='block'>
                    <div className='header-label'>
                      {`${selectedResource} Content` || 'Resource body'}
                    </div>
                    <div className='mb-5'>
                      <Controller
                        name='resourceBody'
                        render={({ field }) => (
                          <Editor
                            {...register('resourceBody')}
                            value={field.value}
                            onEditorChange={field.onChange}
                            ref={field.ref}
                            apiKey='t01292anloqchsxdpsgw1qyoz9hdyvmnx030c0k64o42zyw5'
                            cloudChannel='5-dev'
                            init={{
                              mobile: {
                                menubar: true,
                              },
                              min_height: 700,
                              editor_selector: 'mceEditor',
                              plugins: [
                                'lists link image media paste help wordcount',
                              ],
                              toolbar:
                                'media | undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help | link image | code',
                              media_live_embeds: true,
                              media_poster: false,
                              media_alt_source: false,
                              image_title: true,
                              images_upload_handler: imageUploadHandler,
                              statusbar: false,
                            }}
                          />
                        )}
                        control={control}
                      />
                    </div>
                  </div>
                )}

                {selectedResource === 'Events & Groups' && (
                  <>
                    <FormInput
                      id='eventTitle'
                      type='text'
                      name='eventBody.title'
                      label='Event title'
                      placeholder='Add a title for your event'
                      register={register}
                      errors={errors}
                      rules={{
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Minimum 3 characters is required',
                        },
                        pattern: {
                          value: /^(?=\s*\S).{1,100}$/g,
                          message:
                            'Event title allowed maximum 100 characters only',
                        },
                      }}
                    />
                    <FormInput
                      id='eventInformation'
                      type='textarea'
                      name='eventBody.information'
                      label='Event information'
                      placeholder='Add your event information'
                      register={register}
                      errors={errors}
                    />
                    <div className='form-width'>
                      <div className='row mb-50 max-sm-gap-50'>
                        <div className='col-12 col-sm-6' id='errorDate'>
                          <div className='header-label'>From date</div>
                          <Controller
                            name='eventBody.fromDate'
                            rules={{
                              required: 'This field is required',
                              validate: (fromDate) => {
                                if (
                                  !moment(
                                    getValues().eventBody.toDate
                                  ).isSameOrAfter(moment(fromDate))
                                ) {
                                  return 'Must be same or less than to date';
                                }
                              },
                            }}
                            render={({ field: { ref, value, onChange } }) => {
                              return (
                                <DatePicker
                                  {...register('eventBody.fromDate')}
                                  onChange={(e) => {
                                    onChange(e);
                                    setIsDisabledEndDate(false);
                                  }}
                                  selected={
                                    value ? moment(value).toDate() : null
                                  }
                                  ref={ref}
                                  customInput={<CustomDatePicker />}
                                  minDate={moment().toDate()}
                                  dateFormat='dd/MM/yyyy'
                                />
                              );
                            }}
                            control={control}
                          />
                          {errors?.eventBody?.fromDate && (
                            <ErrorMessage
                              errors={errors}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              name='eventBody.fromDate'
                              render={({ message }) => (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              )}
                            />
                          )}
                        </div>
                        <div className='col-12 col-sm-6'>
                          <div className='header-label'>To date</div>
                          <Controller
                            name='eventBody.toDate'
                            rules={{
                              required: 'This field is required',
                            }}
                            render={({ field: { ref, value, onChange } }) => {
                              return (
                                <DatePicker
                                  {...register('eventBody.toDate')}
                                  onChange={(e) => {
                                    onChange(e);
                                    trigger('eventBody.fromDate');
                                    trigger('eventBody.startsOn');
                                  }}
                                  disabled={isDisabledEndDate}
                                  selected={
                                    value ? moment(value).toDate() : null
                                  }
                                  ref={ref}
                                  customInput={<CustomDatePicker />}
                                  minDate={moment(
                                    getValues().eventBody.fromDate
                                  ).toDate()}
                                  dateFormat='dd/MM/yyyy'
                                />
                              );
                            }}
                            control={control}
                          />
                          {errors?.eventBody?.toDate && (
                            <ErrorMessage
                              errors={errors}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              name='eventBody.toDate'
                              render={({ message }) => (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <span
                        id='errMsg'
                        style={{ color: 'rgb(195, 31, 31)', fontSize: '16px' }}
                      >
                        {errorDate}
                      </span>
                      <div className='row mb-50 max-sm-gap-50'>
                        <div className='col-12 col-sm-6'>
                          <div className='header-label'>Starts on</div>
                          <Controller
                            name='eventBody.startsOn'
                            rules={{
                              required: 'This field is required',
                              validate: (date) => {
                                let startDate = moment(
                                  getValues().eventBody.fromDate
                                ).format('YYYY-MM-DD');
                                let startsOn = moment(date).format('HH:mm');
                                let startDateTime = moment(
                                  startDate + ' ' + startsOn
                                );
                                let isGreaterThanCurrentTime = moment(
                                  startDateTime
                                ).isAfter(moment());

                                let endDate = moment(
                                  getValues().eventBody.toDate
                                ).format('YYYY-MM-DD');
                                let endsOn = moment(
                                  getValues().eventBody.endsOn
                                ).format('HH:mm');
                                let endDateTime = moment(
                                  endDate + ' ' + endsOn
                                );

                                let isBeforeEndTime = moment(
                                  endDateTime
                                ).isAfter(moment(startDateTime));

                                if (!isGreaterThanCurrentTime) {
                                  return 'Must be greater than current time';
                                } else if (!isBeforeEndTime) {
                                  return 'Must be less than end time';
                                }
                              },
                            }}
                            render={({ field: { ref, value, onChange } }) => {
                              return (
                                <DatePicker
                                  {...register('eventBody.startsOn')}
                                  onChange={(e) => {
                                    let startDate = moment(
                                      getValues().eventBody.fromDate
                                    ).format('YYYY-MM-DD');
                                    let startsOn = moment(e).format('HH:mm');
                                    let startDateTime = moment(
                                      startDate + ' ' + startsOn
                                    ).toDate();
                                    onChange(startDateTime);
                                    setIsDisabledEndsOn(false);
                                  }}
                                  selected={moment(value).toDate()}
                                  ref={ref}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  customInput={<CustomTimePicker />}
                                />
                              );
                            }}
                            control={control}
                          />
                          {errors?.eventBody?.startsOn && (
                            <ErrorMessage
                              errors={errors}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              name='eventBody.startsOn'
                              render={({ message }) => (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              )}
                            />
                          )}
                        </div>
                        <div className='col-12 col-sm-6'>
                          <div className='header-label'>Ends on</div>
                          <Controller
                            name='eventBody.endsOn'
                            rules={{
                              required: 'This field is required',
                            }}
                            render={({ field: { ref, value, onChange } }) => {
                              return (
                                <DatePicker
                                  {...register('eventBody.endsOn')}
                                  onChange={(e) => {
                                    trigger('eventBody.startsOn');
                                    trigger('eventBody.fromDate');
                                    trigger('eventBody.toDate');
                                    let endDate = moment(
                                      getValues().eventBody.toDate
                                    ).format('YYYY-MM-DD');
                                    let endsOn = moment(e).format('HH:mm');
                                    let endDateTime = moment(
                                      endDate + ' ' + endsOn
                                    ).toDate();
                                    onChange(endDateTime);
                                  }}
                                  selected={moment(value).toDate()}
                                  disabled={isDisabledEndon}
                                  ref={ref}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption='Time'
                                  dateFormat='h:mm aa'
                                  customInput={<CustomTimePicker />}
                                />
                              );
                            }}
                            control={control}
                          />
                          {errors?.eventBody?.endsOn && (
                            <ErrorMessage
                              errors={errors}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              name='eventBody.endsOn'
                              render={({ message }) => (
                                <FormErrorMessage>{message}</FormErrorMessage>
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <FormInput
                      id='location'
                      type='text'
                      name='eventBody.location'
                      label='Location (optional)'
                      placeholder='Add location for your event'
                      register={register}
                      errors={errors}
                    />
                    <FormInput
                      id='eventLink'
                      type='text'
                      name='eventBody.link'
                      label='Event link'
                      placeholder='Add link for your event'
                      register={register}
                      errors={errors}
                    />
                  </>
                )}
                <div className='block'>
                  {!Constants.CONTENT_TYPE_STATIC_LIST.includes(
                    selectedResource
                  ) &&
                    selectedResType === 'Video' &&
                    renderVideoSection()}
                  <FormInput
                    id='tags'
                    type='search'
                    name='tagValue'
                    label='Add tags'
                    placeholder='Enter tags and press enter to add'
                    linkText={
                      <span
                        tabIndex={tabIndex}
                        role='button'
                        onClick={() => {
                          setCurrentTagModalState(true);
                          fetchTagLists();
                        }}
                        className=' underline-btn primary mt-3'
                      >
                        View list of current tags
                      </span>
                    }
                    onKeyDown={onEnter}
                    register={register}
                    errors={errors}
                  />
                  <div className='flex gap-4'>
                    {selectedTags &&
                      selectedTags?.map((tag: any, key) => (
                        <>
                          <Tags
                            key={tag?.id}
                            customKey={tag?.id}
                            name={tag?.name}
                            removeTags={removeTags}
                          />
                        </>
                      ))}
                  </div>
                </div>
                {/* <div className="header-label ">Publish for</div>
                <div className="grid gap-4 ">
                  <FormCheckBox
                    type="radio"
                    id="user"
                    label={`All Trusts within ${jwtDecode.systemAdminName}`}
                    name="publishFor"
                    value="allUsers"
                    register={register}
                    rules={{
                      required: "This field is required",
                    }}
                    errors={errors}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPublishFor(e.target.value);
                      setIsFieldChanged(true);
                    }}
                    isMobile={isMobile}
                  />
                  <FormCheckBox
                    type="radio"
                    id="specificService"
                    label="My Trust and a specific Trust"
                    name="publishFor"
                    value="specificService"
                    register={register}
                    rules={{
                      required: "This field is required",
                    }}
                    errors={errors}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPublishFor(e.target.value);
                      setIsFieldChanged(true);
                    }}
                    isMobile={isMobile}
                  />
                  <FormCheckBox
                    type="radio"
                    id="myTrust"
                    label="My Trust only"
                    name="publishFor"
                    value="myTrust"
                    register={register}
                    rules={{
                      required: "This field is required",
                    }}
                    errors={errors}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPublishFor(e.target.value);
                      setIsFieldChanged(true);
                    }}
                    isMobile={isMobile}
                  />
                </div> */}

                {/* <div className="row g-0">
                  <div className="col-12 col-md-4"></div>

                  {publishFroValue === "specificService" &&
                    serviceTrusts.length !== 0 && (
                      <>
                        <div className="mb-4" />
                        <div className="header-label">Select Trust</div>
                      </>
                    )}

                  {publishFroValue === "specificService" &&
                    serviceTrusts.length !== 0 &&
                    serviceTrusts?.map((item, index) => {
                      if (item?._id !== jwtDecode.trustId) {
                        return (
                          <>
                            <div className="row g-0 mb-0 mb-md-4">
                              <div className="col-12 col-md-4">
                                <FormCheckBox
                                  type="checkbox"
                                  id={`contentApprover_popup-${item?._id} `}
                                  label={item?.name}
                                  name={item?._id}
                                  value={item?._id}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedServiceTrust([
                                        ...selectedServiceTrust,
                                        e.target.value,
                                      ]);
                                    } else {
                                      setSelectedServiceTrust(
                                        selectedServiceTrust.filter(
                                          (item: any) => item !== e.target.value
                                        )
                                      );
                                    }
                                  }}
                                  checked={selectedServiceTrust
                                    .map((item: any) => item)
                                    .includes(item?._id)}
                                  register={register}
                                  rules={
                                    {
                                      // required: "This field is required",
                                    }
                                  }
                                  errors={errors}
                                  isMobile={isMobile}
                                />
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                </div> */}
                {/* {errors && errors.publishFor && errors.publishFor.message ? (
                  <ErrorMessage
                    errors={errors}
                    name="publishFor"
                    render={({ message }) => (
                      <FormErrorMessage>{message}</FormErrorMessage>
                    )}
                  />
                ) : null} */}

                {/* <FormSelect
                  id="selectedApprover"
                  name="selectedApprover"
                  label="Approvers"
                  placeholder="Please select an user"
                  options={resourceDropdown}
                  labelKey="email"
                  valueKey="email"
                  control={control}
                  register={register}
                  onSelectChange={(value: any, selectedObj: any) =>
                    onApproverUserChange(value, selectedObj)
                  }
                  rules={{
                    required: "This field is required",
                  }}
                  errors={errors}
                /> */}
              </div>
              <div className='app-sub-header multi-btn mt-10'>
                <button
                  className={'btn halo' + (isMobile ? ' mt-2' : '')}
                  onClick={handleSubmit(onSubmit)}
                  type='button'
                  disabled={(!isDirty || !isValid) && !isFieldChanged}
                >
                  Save changes
                </button>

                <button
                  className={'btn primary-btn' + (isMobile ? ' mt-2' : '')}
                  disabled={!isDirty || !isValid}
                  type='submit'
                >
                  Submit for approval
                </button>
                {shortenRole.includes(scopeProperty.RESOURCE_PUBLISH) && (
                  <button
                    onClick={handleSubmit(publishResource)}
                    className={'btn primary-btn' + (isMobile ? ' mt-2' : '')}
                    disabled={!isDirty || !isValid}
                    type='submit'
                  >
                    Publish
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceForm;
